import React from 'react';
import { RootState } from './store/store'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import { App as AntApp, ConfigProvider, theme } from 'antd';
import Notification from './Components/common/Notification'
import App from './App';

import ruRU from 'antd/locale/ru_RU'
import { ThemeConfig } from 'antd/lib';
import { VideoPlayerModal } from './Components/VideoPlayer/VideoPlayerModal';

const AppProvider: React.FC = () => {

    let currentAlgorithm = useSelector((state: RootState) => {
        return state.designMode.currentAlgorithm
    })

    let darkAlgorithmToken: ThemeConfig = {
        algorithm: theme.darkAlgorithm,
        inherit: false,
        components:{
            Table:{
                algorithm: theme.darkAlgorithm,
                colorBgContainer: '--Table-table-bg',
                bodySortBg: '--Table-table-bg',
                headerSortActiveBg: '--Table-table-bg',
                headerBg: 'transparent',
                rowHoverBg: 'var(--Table-table-item-active)',
                colorTextBase: '#ffaabb',
                colorText: '#121724',

                headerSortHoverBg: '--Table-table-bg',
                cellPaddingBlock: 12,
                fontFamily: 'Montserrat',
                fontSize: 14,
                fontWeightStrong: 500,
                headerColor: 'var(--Text-text-placeholder, #979AA4)',
            },
            Select:{
                algorithm: theme.darkAlgorithm,
                colorTextPlaceholder: 'var(--Text-text-placeholder)',
                colorBgContainer: 'var(--background-bg-light)',
                colorBgElevated: 'var(--background-bg-hint,  #1E283ECC); backdrop-filter: blur(10px);',
                optionSelectedBg: '--Button-button-active',
                selectorBg: '--background-bg-hint',
            },
            DatePicker: {
                algorithm: theme.darkAlgorithm,
                colorTextPlaceholder: '--Text-text-placeholder',
                colorBgContainer: '--background-bg-light; backdrop-filter: blur(10px);',
                colorBgElevated:  'var(--background-bg-hint, #1E283ECC)',
            },
            Input:{
                
                colorBgContainer: 'var(--background-bg-light)',
                colorTextPlaceholder: 'var(--Text-text-placeholder, #979AA4)'
            },
            Modal:{
                algorithm: theme.darkAlgorithm,
                headerBg: 'var(--background-bg-light)',
                contentBg: 'var(--background-bg-light)',
                footerBg:  'var(--background-bg-light)',
            },
            Pagination:{
                algorithm: theme.darkAlgorithm,
                itemBg: 'transparent',
                itemActiveBg: 'transparent',
                colorPrimary: '#7479F6'
            },
            Checkbox:{
                algorithm: theme.darkAlgorithm,
                colorBgContainer: 'transparent',
                colorBorder: 'var(--Border-border-dark, #6F7483)',
                colorPrimary: '#7479F6',
                colorPrimaryBorder: '#7479F6',
                // colorBgTextActive: 
            },
            Button:{
                algorithm: theme.darkAlgorithm,
                colorPrimary: '#7479F6',
                primaryColor: 'var(--Text-text-default, #FDFDFD)',
                primaryShadow: 'transparent',
                colorPrimaryHover: 'var(--Button-button-hover, #9A9DF8)',
                colorPrimaryBgHover: 'var(--Button-button-active, #7479F6)',
                colorPrimaryTextHover: 'var(--Text-text-default, #FEFEFE)',
                defaultActiveColor: 'var(--Text-text-default, #FEFEFE)',
                colorBgContainerDisabled: 'var(--Button-button-default, #232E48)',
                defaultActiveBg: 'var(--Button-button-default, #232E48)',
                defaultActiveBorderColor: 'var(--Button-button-active, #7479F6)',
                fontFamily: 'Montserrat',
                fontWeight: 600,
                fontSize: 12,
                defaultHoverBorderColor: 'var(--Button-button-hover, #9A9DF8)',
                defaultHoverColor: 'var(--Button-button-hover, #9A9DF8)',
                defaultBg: 'transparent',
                defaultBorderColor:  'var(--Button-button-active, #7479F6)',
                defaultColor: 'var(--Button-button-active, #7479F6)',
                defaultHoverBg: 'var(--Button-button-default, #232E48)',

            },
            Collapse: {
                algorithm: theme.darkAlgorithm,
                colorTextHeading: 'var(--Text-text-default, #FDFDFD)',
                contentBg: 'var(--background-bg-pages, #121724)'

            },
            Tabs: {
                itemActiveColor: 'var(--Text-text-special, #7479F6)',
                inkBarColor: 'var(--Text-text-special, #7479F6)',
                itemSelectedColor: 'var(--Text-text-special, #7479F6)',
                itemHoverColor: 'var(--Button-button-hover, #9A9DF8)',
                fontFamily: 'Montserrat',
                fontSize: 18,
            },
            Switch: {
                colorPrimary: 'var(--Text-text-special, #7479F6)',
                colorPrimaryHover: 'var(--Button-button-hover, #9A9DF8)',
            },
            Card: {
                borderRadius: 16

            },
            Tooltip:{
                fontFamily: 'Montserrat', 
                fontSize: 12,
            }
        }
    }

    let lightAlgorithmToken: ThemeConfig = {
        algorithm: theme.defaultAlgorithm,
        inherit: false,
        components:{
            Table:{
                // algorithm: theme.darkAlgorithm,
                // colorBgContainer: '--Table-table-bg',
                // bodySortBg: '--Table-table-bg',
                // headerSortActiveBg: '--Table-table-bg',
                // headerBg: '--Text-text-placeholder',
                // rowHoverBg: 'var(--background-bg-hint)',
                rowHoverBg: 'var(--Table-table-item-active)',
                // colorTextBase: '#ffaabb',
                cellPaddingBlock: 12,
                fontFamily: 'Montserrat',
                fontSize: 14,
                fontWeightStrong: 500,
                headerColor: 'var(--Text-text-placeholder, #979AA4)',
                headerBg: 'transparent'
            },
            Select:{
                // algorithm: theme.darkAlgorithm,
                // colorTextPlaceholder: 'var(--Text-text-placeholder)',
                // colorBgContainer: 'var(--background-bg-light);',
                // colorBgElevated: 'var(--background-bg-hint,  #1E283ECC)',
                // optionSelectedBg: '--Button-button-active',
                // selectorBg: '--background-bg-hint;',
            },
            DatePicker: {
                // algorithm: theme.defaultAlgorithm,
                // colorText: '#FEFEFE;',
                // colorBgBlur: '--Text-text-placeholder;',
                // colorTextPlaceholder: '--Text-text-placeholder;',
                // colorBgContainer: 'var(--background-bg-light);  backdrop-filter: blur(10px);',
                // colorBgElevated:  'var(--background-bg-hint, #1E283ECC)',
            },
            Input:{
                colorBgContainer: 'var(--background-bg-light)',
                colorTextPlaceholder: 'var(--Text-text-placeholder, #979AA4)'
            },
            Modal:{
                algorithm: theme.defaultAlgorithm,
                headerBg: 'var(--background-bg-light)',
                contentBg: 'var(--background-bg-light)',
                footerBg:  'var(--background-bg-light)',
            },
            Pagination:{
                algorithm: theme.defaultAlgorithm,
                itemBg: 'transparent',
                itemActiveBg: 'transparent',
                colorPrimary: '#7479F6'
            },
            Checkbox:{
                algorithm: theme.defaultAlgorithm,
                colorBgContainer: 'transparent',
                colorBorder: 'var(--Border-border-dark, #6F7483)',
                colorPrimary: '#7479F6',
                colorPrimaryBorder: '#7479F6',
                // colorBgTextActive: 
            },
            Button:{
                algorithm: theme.defaultAlgorithm,
                colorPrimary: '#7479F6',
                primaryColor: 'var(--Text-text-default, #FDFDFD)',
                primaryShadow: 'transparent',
                colorPrimaryHover: 'var(--Button-button-hover, #9A9DF8)',
                colorPrimaryBgHover: 'var(--Button-button-active, #7479F6)',
                colorPrimaryTextHover: 'var(--Text-text-default, #FEFEFE)',
                defaultActiveColor: 'var(--Text-text-default, #FEFEFE)',
                colorBgContainerDisabled: 'var(--Border-border-light, #DBDBE080)',
                defaultActiveBg: 'var(--Button-button-default, #F3F3F7)',
                defaultActiveBorderColor: 'var(--Button-button-active, #7479F6)',
                fontFamily: 'Montserrat',
                fontWeight: 600,
                fontSize: 12,
                defaultBg: 'transparent',
                defaultColor: 'var(--Text-text-special, #7479F6)',
                defaultHoverBorderColor: 'var(--Button-button-hover, #9A9DF8)',
                defaultHoverColor: 'var(--Button-button-hover, #9A9DF8)',
                defaultBorderColor: 'var(--Button-button-active, #7479F6)',
                defaultHoverBg: 'var(--Button-button-default, #F3F3F7)',
            },
            Collapse: {
                algorithm: theme.defaultAlgorithm,
                colorTextHeading: 'var(--Text-text-black, #121724)'
            },
            Tabs: {
                itemActiveColor: 'var(--Text-text-special, #7479F6)',
                inkBarColor: 'var(--Text-text-special, #7479F6)',
                itemSelectedColor: 'var(--Text-text-special, #7479F6)',
                itemHoverColor: 'var(--Button-button-hover, #9A9DF8)',
                fontFamily: 'Montserrat',
                fontSize: 18,
            },
            Switch: {
                colorPrimary: 'var(--Text-text-special, #7479F6)',
                colorPrimaryHover: 'var(--Button-button-hover, #9A9DF8)',
            },
            Card: {
                borderRadius: 16
            },
            Tooltip:{
                colorText: 'var(--Text-text-default, #FEFEFE)',
                fontFamily: 'Montserrat', 
                fontSize: 12,
            }
        }
    }
    
    const currentToken = (currentAlgorithm==='dark')?darkAlgorithmToken : lightAlgorithmToken

    return <ConfigProvider theme={{
            // 1. Use dark algorithm
            ...currentToken
            // cssVar: true
        }}
        locale={ruRU}

        >
            <AntApp className={currentAlgorithm+'_theme'}>
                <BrowserRouter>
                    <App />
                    <Notification />
                    <div id='modals_container'></div>
                    <VideoPlayerModal /> 
                </BrowserRouter>
            </AntApp>
        </ConfigProvider>

}
export default AppProvider